<template>
  <div>
    <member-setting-container>
      <b-card>
        <div class="d-flex justify-content-between align-items-center">
          <div class="d-flex my-1">
            <h4>{{ $t('members.member-detail.my-connections')}}</h4>
            <b-badge v-if="total" variant="primary" class="align-self-start ml-50">
              {{ total }}
            </b-badge>
          </div>
          <div>
            <feather-icon 
              v-if="total > 0" 
              icon="Edit2Icon"
              size="18"
              class="text-primary"
              style="cursor: pointer" 
              @click="isEditing = !isEditing"
            />
            <b-button
              variant="link"
              @click="wantConnection=true"
            >
              <feather-icon  icon="PlusIcon" size="18"></feather-icon>
            </b-button>
          </div>
        </div>
        <!-- Connections table -->
        <member-connections
          :member="loggedMember"
          :is-staff="true"
          :is-my-self="true"
          :is-editing="isEditing"
          :want-connection="wantConnection"
          @wantConnections="wantConnection = false"
          @total="getTotal"
        />
      </b-card>
    </member-setting-container>
    
    <!-- RECOMMENDATIONS -->
    <member-setting-container>
      <b-card>
        <div class="d-flex justify-content-between align-items-center">
          <div class="d-flex my-1">
            <h4>{{ $t('members.member-detail.other-members-you-may-like')}}</h4>
            <b-badge v-if="total2" variant="primary" class="align-self-start ml-50">
              {{ total2 }}
            </b-badge>
          </div>
        </div>
        <!-- Recommendations table -->
        <member-recommendations
          :member="loggedMember"
          :is-staff="true"
          :is-my-self="true"
          :is-editing="false"
          :want-connection="false"
          @wantConnections="wantConnection = false"
          @total2="getTotal2"
        />
      </b-card>
    </member-setting-container>
  </div>
  
</template>

<script>
import MemberSettingContainer from '@/views/myself/components/MemberSettingContainer.vue';

export default {
  name: 'Connections',
  components: {
    MemberSettingContainer,
    MemberConnections: () => import("@/views/apps/member/components/MemberConnections.vue" /* webpackChunkName: "MemberConnections" */),
    MemberRecommendations: () => import("@/views/apps/member/components/MemberRecommendations.vue" /* webpackChunkName: "MemberRecommendations" */),
  },
  data() {
    return {
      total: null,
      total2: null,
      isEditing: false,
      wantConnection: false,
    };
  },
  computed: {
    loggedMember() {
      return this.$store.getters.loggedMember;
    },
  },
  async created() {
    //
  },
  methods: {
    getTotal(total) {
      this.total = total;
    },
    getTotal2(total2) {
      this.total2 = total2;
    },
  },
};
</script>